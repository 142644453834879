// tempexp_37037_file
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { CountryId } from 'src/models/countryId';

export const EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER = 'exp_37037_free_rise_for_new';

interface UseFreeRiseForNewEmployer {
    isFreeRiseForNewEmployerEnabled: boolean;
    shouldCheckFreeRiseForNewEmployerExp: boolean;
}

export const useFreeRiseForNewEmployer = (): UseFreeRiseForNewEmployer => {
    const isRussianEmployer = useSelector(
        ({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString() === CountryId.Russia
    );
    const isNewEmployerFromPrice = useSelector(
        ({ priceProfRolesPublications }) => priceProfRolesPublications?.isNewEmployerWithoutServices
    );
    const isNewEmployerFromBuilder = useSelector(
        ({ availablePublicationTypes }) => availablePublicationTypes.data?.isNewEmployerWithoutServices
    );
    const isNewEmployerFromDashboard = useSelector(({ isNewEmployerWithoutServices }) => isNewEmployerWithoutServices);
    const isZp = useIsZarplataPlatform();
    const isHHRu = useIsHhru();
    const shouldCheckExperiment =
        !isZp &&
        isHHRu &&
        isRussianEmployer &&
        (isNewEmployerFromPrice || isNewEmployerFromBuilder || isNewEmployerFromDashboard);
    const isFreeRiseForNewEmployerEnabled = useExperiment(
        EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER,
        shouldCheckExperiment,
        false
    );

    return {
        isFreeRiseForNewEmployerEnabled,
        shouldCheckFreeRiseForNewEmployerExp: shouldCheckExperiment,
    };
};
