// tempexp_37037_file
import { FC } from 'react';

import { Action, ActionBar, BottomSheet, Button, Modal, Text, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

interface FreeRiseModalExpProps {
    visible: boolean;
    onClose: () => void;
}

const FreeRiseModalExp: FC<FreeRiseModalExpProps> = ({ visible, onClose }) => {
    const title = 'Бесплатно продлите первую вакансию, если будет мало откликов';
    const getContent = (isMobile?: boolean) => {
        const typography = isMobile ? 'paragraph-2-regular' : 'paragraph-1-regular';

        return (
            <>
                <Text typography={typography}>
                    Если&nbsp;вакансия «Стандарт», «Стандарт плюс» или&nbsp;«Премиум» наберёт меньше 5&nbsp;откликов
                    в&nbsp;течение 30&nbsp;дней&nbsp;— начислим бесплатную публикацию, чтобы&nbsp;продлить вакансию
                    ещё&nbsp;на&nbsp;30&nbsp;дней с&nbsp;тем&nbsp;же&nbsp;тарифом.
                </Text>
                <VSpacing default={8} />
                <Text typography={typography}>
                    Если&nbsp;в&nbsp;первые 30&nbsp;дней переместите вакансию в&nbsp;архив&nbsp;— бесплатное продление
                    для&nbsp;неё&nbsp;отменится.
                </Text>
                <VSpacing default={8} />
                <Text typography={typography}>
                    Предложение действует только на&nbsp;одну и&nbsp;первую вакансию, опубликованную в&nbsp;период акции
                    с&nbsp;15&nbsp;января по&nbsp;15&nbsp;февраля
                </Text>
            </>
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={onClose}
                size="small"
                title={title}
                titleSize="medium"
                actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={onClose} />}
                footer={
                    <ActionBar
                        type="modal-vertical"
                        primaryActions={
                            <Button mode="primary" size="large" style="accent" stretched onClick={onClose}>
                                Закрыть
                            </Button>
                        }
                    />
                }
            >
                {getContent()}
            </Modal>
            <BottomSheet visible={visible} onClose={onClose}>
                <VSpacing default={16} />
                <Text typography="title-4-semibold">{title}</Text>
                <VSpacing default={16} />
                {getContent(true)}
                <VSpacing default={16} />
            </BottomSheet>
        </>
    );
};

export default FreeRiseModalExp;
