// tempexp_38298_file

import { type FC, useEffect, useState } from 'react';

import jobTypeCardButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/other/job_type_card_button_click';
import jobTypeScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/other/job_type_screen_shown';
import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Cell,
    CellText,
    CheckableCard,
    Checkbox,
    Modal,
    NavigationBar,
    ProgressBar,
    ProgressBarProps,
    Text,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import {
    BriefcaseOutlinedSize24,
    ChevronLeftOutlinedSize24,
    ChevronRightOutlinedSize24,
    CrossOutlinedSize24,
    WalletOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import GoToSearchButton from 'src/components/Tempexp38298/GoToSearchButton';
import {
    BULLET_SYMBOL,
    EMPLOYMENT_OPTION_TRLS,
    EmploymentOption,
    Hint,
    HINT_BULLETS,
    HINT_TYPICAL_JOBS,
    JOB_OPTION_TRLS,
    JOB_OPTIONS_C_FULL_TIME,
    JOB_OPTIONS_C_FULL_TIME_WITHOUT_TREK,
    JOB_OPTIONS_C_PART_TIME,
    JOB_OPTIONS_C_PART_TIME_WITHOUT_TREK,
    JobOption,
    ScreenType,
    SelectableJobOptions,
} from 'src/components/Tempexp38298/types';

const TRLS = {
    employmentOptionTitle: 'Какую работу вам показать?',
    jobOptionTitle: 'Какой формат удобнее?',
    hideModal: 'Закрыть',
    resetEmploymentOption: 'Назад',
    hybridHint: 'Нужно приходить в офис несколько раз в неделю, а в остальные дни можно работать из дома',
};

interface ModalCProps {
    isModalVisible: boolean;
    hideModal: () => void;
}

const ModalC: FC<ModalCProps> = ({ isModalVisible, hideModal }) => {
    const { isMobile } = useBreakpoint();
    const [employmentOption, setEmploymentOption] = useState<null | EmploymentOption>(null);
    const [selectedJobOptions, setSelectedJobOptions] = useState<SelectableJobOptions>({});
    const [trek, setTrek] = useState<JobOption.Constant | JobOption.Temporary | undefined>();

    useEffect(() => {
        if (!isModalVisible) {
            return;
        }

        jobTypeScreenShown({
            hhtmSource: selectedJobOptions ? ScreenType.FormatSelect : ScreenType.TypeSelect,
        });
    }, [selectedJobOptions, isModalVisible]);

    const resetEmploymentOption = () => {
        setEmploymentOption(null);
        setTrek(undefined);
        setSelectedJobOptions({});
    };

    const setEmploymentOptionToFullTime = () => {
        setEmploymentOption(EmploymentOption.FullTime);
        setTrek(JobOption.Constant);
        setSelectedJobOptions({
            ...JOB_OPTIONS_C_FULL_TIME,
            [JobOption.Constant]: true,
        });
    };

    const setEmploymentOptionToPartTime = () => {
        setEmploymentOption(EmploymentOption.PartTime);
        setTrek(JobOption.Temporary);
        setSelectedJobOptions({
            ...JOB_OPTIONS_C_PART_TIME,
            [JobOption.Temporary]: true,
        });
    };

    const renderCheckableCards = () =>
        Object.entries(selectedJobOptions).map(([jobOption, isChecked]) => {
            const checkbox = <Checkbox checked={isChecked} readOnly />;
            const left = isMobile ? undefined : checkbox;
            const right = isMobile ? checkbox : undefined;

            const label = JOB_OPTION_TRLS[jobOption as JobOption];

            if (!label) {
                return null;
            }

            return (
                <CheckableCard
                    key={jobOption}
                    type="checkbox"
                    checked={isChecked}
                    padding={16}
                    borderRadius={16}
                    onChange={() => {
                        setSelectedJobOptions((currentSelectedJobOptions) => {
                            const selection = {
                                ...currentSelectedJobOptions,
                                [jobOption]: !currentSelectedJobOptions[jobOption as JobOption],
                            };

                            if (trek === JobOption.Constant) {
                                selection[JobOption.Constant] = Object.keys(JOB_OPTIONS_C_FULL_TIME_WITHOUT_TREK).every(
                                    (option) => !selection[option]
                                );
                            }

                            if (trek === JobOption.Temporary) {
                                selection[JobOption.Temporary] = Object.keys(
                                    JOB_OPTIONS_C_PART_TIME_WITHOUT_TREK
                                ).every((option) => !selection[option]);
                            }

                            return selection;
                        });
                    }}
                >
                    <Cell left={left} right={right}>
                        <CellText>{label}</CellText>
                        {jobOption === JobOption.Hybrid && (
                            <CellText type="subtitle" style="secondary">
                                {TRLS.hybridHint}
                            </CellText>
                        )}
                    </Cell>
                </CheckableCard>
            );
        });

    const renderEmploymentOptions = () => {
        const employmentOptions = [
            {
                title: EMPLOYMENT_OPTION_TRLS[EmploymentOption.FullTime],
                buttonName: 'fulltime',
                bullets: HINT_BULLETS[Hint.FullTime],
                typicalJobs: HINT_TYPICAL_JOBS[Hint.FullTime],
                icon: <BriefcaseOutlinedSize24 initial="accent" />,
                jobOption: JobOption.Constant,
                onClick: setEmploymentOptionToFullTime,
            },
            {
                title: EMPLOYMENT_OPTION_TRLS[EmploymentOption.PartTime],
                buttonName: 'side_job',
                bullets: HINT_BULLETS[Hint.PartTimeExtended],
                typicalJobs: HINT_TYPICAL_JOBS[Hint.PartTimeExtended],
                icon: <WalletOutlinedSize24 initial="special" />,
                jobOption: JobOption.Temporary,
                onClick: setEmploymentOptionToPartTime,
            },
        ] as const;

        return (
            <VSpacingContainer default={16}>
                {employmentOptions.map(({ title, buttonName, bullets, typicalJobs, icon, jobOption, onClick }) => (
                    <Card
                        key={title}
                        actionCard
                        stretched
                        showBorder
                        showShadowOnHover
                        padding={24}
                        borderRadius={24}
                        onClick={() => {
                            jobTypeCardButtonClick({
                                buttonName,
                                hhtmSource: 'job_type_select',
                            });

                            setSelectedJobOptions((currentSelectedJobOptions) => ({
                                ...currentSelectedJobOptions,
                                [jobOption]: !currentSelectedJobOptions[jobOption as JobOption],
                            }));

                            onClick();
                        }}
                    >
                        <VSpacingContainer default={16}>
                            <Cell left={icon} right={<ChevronRightOutlinedSize24 />}>
                                <Text typography="subtitle-1-semibold">{title}</Text>
                            </Cell>
                            <VSpacingContainer default={2}>
                                {bullets.map((bullet) => (
                                    <Text key={bullet} typography="label-2-regular">
                                        {`${BULLET_SYMBOL} ${bullet}`}
                                    </Text>
                                ))}
                            </VSpacingContainer>
                            <Text style="secondary" typography="label-3-regular">
                                {typicalJobs}
                            </Text>
                        </VSpacingContainer>
                    </Card>
                ))}
            </VSpacingContainer>
        );
    };

    const renderContent = () => {
        const content = employmentOption ? renderCheckableCards() : renderEmploymentOptions();

        return (
            <>
                <VSpacingContainer default={12}>{content}</VSpacingContainer>
                <VSpacing default={24} />
            </>
        );
    };

    const title = employmentOption ? TRLS.jobOptionTitle : TRLS.employmentOptionTitle;
    const content = renderContent();
    const goToSearchButton = (
        <GoToSearchButton selectedJobOptions={selectedJobOptions} screenType={ScreenType.FormatSelect} />
    );
    const hideModalButton = (
        <Button mode="tertiary" style="accent" onClick={hideModal}>
            {TRLS.hideModal}
        </Button>
    );
    const resetEmploymentOptionButton = (
        <Button mode="secondary" style="accent" onClick={resetEmploymentOption}>
            {TRLS.resetEmploymentOption}
        </Button>
    );
    const bottomSheetFooter = employmentOption ? <BottomSheetFooter>{goToSearchButton}</BottomSheetFooter> : null;
    const progressBarProps: ProgressBarProps = {
        progress: employmentOption ? 2 : 1,
        segments: 2,
    };

    return (
        <>
            <Modal
                titleSize="large"
                visible={isModalVisible && !isMobile}
                onClose={hideModal}
                title={title}
                footer={
                    <ActionBar
                        type="modal"
                        primaryActions={employmentOption ? goToSearchButton : undefined}
                        secondaryActions={
                            <>
                                {hideModalButton}
                                {employmentOption ? resetEmploymentOptionButton : null}
                            </>
                        }
                        showProgress={<ProgressBar {...progressBarProps} />}
                    />
                }
            >
                {content}
            </Modal>

            <BottomSheet
                visible={isModalVisible && isMobile}
                header={
                    <NavigationBar
                        title={title}
                        size="large"
                        left={
                            employmentOption ? (
                                <Action
                                    icon={ChevronLeftOutlinedSize24}
                                    mode="secondary"
                                    style="neutral"
                                    onClick={resetEmploymentOption}
                                />
                            ) : undefined
                        }
                        right={<Action icon={CrossOutlinedSize24} onClick={hideModal} />}
                        progress={progressBarProps}
                    />
                }
                footer={bottomSheetFooter}
                onClose={hideModal}
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default ModalC;
