import { useCallback } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useExperimentCheckOnElementShown } from '@hh.ru/front-static-app';
import { VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import FreeRiseMobileBannerExp from 'src/components/Employer/FreeRiseMobileBannerExp';
import FreeRiseModalExp from 'src/components/Employer/FreeRiseModalExp';
import ContactPhonesTempexp from 'src/components/IndexPageNewEmployer/components/ContactPhonesTempexp';
import PreparedVacancyTemplates from 'src/components/IndexPageNewEmployer/components/PreparedVacancyTemplates';
import {
    useFreeRiseForNewEmployer,
    EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER,
} from 'src/components/Price/hooks/useFreeRiseForNewEmployer';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import BilledVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/BilledVacancy';
import FreeRiseBannerExp from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/FreeRiseBannerExp';
import RecentDraftVacancy from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/RecentDraftVacancy';
import ScheduledPublication from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/ScheduledPublication';
import VacancyStub from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/VacancyStub';
import VacancyStubTempexp from 'src/components/IndexPageNewEmployer/components/DashboardVacancy/VacancyStubTempexp';

import styles from './dashboard-vacancy.less';

// tempexp_35925_next-line: bigHelpEnabled
const DashboardVacancy = ({ bigHelpEnabled }) => {
    const { draft, bill, giftsCount, employerRegionTrl, employerRegionResumesCount, nextDraftScheduled } = useSelector(
        (state) => state.newEmployerDashboardVacancy
    );
    // tempexp_37037_start
    const { isFreeRiseForNewEmployerEnabled, shouldCheckFreeRiseForNewEmployerExp } = useFreeRiseForNewEmployer();
    const [visible, visibleOn, visibleOff] = useOnOffState(false);
    const { isGtS } = useBreakpoint();
    const ref = useExperimentCheckOnElementShown({
        experimentNames: [EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER],
        // Отправка события участия в эксперименте для мобилок обрабатывается отдельно
        sendCheck: shouldCheckFreeRiseForNewEmployerExp && isGtS,
    });

    const handleClickMore = useCallback(() => {
        Analytics.sendHHEventButtonClick('banner_free_update_more_main');
        visibleOn();
    }, [visibleOn]);
    // tempexp_37037_end

    const renderVacancy = () => {
        if (draft && bill) {
            return <BilledVacancy vacancy={draft} bill={bill} giftsCount={giftsCount} />;
        }

        if (draft) {
            return <RecentDraftVacancy vacancy={draft} />;
        }

        if (nextDraftScheduled) {
            return <ScheduledPublication data={nextDraftScheduled} />;
        }

        // tempexp_35925-start
        if (bigHelpEnabled) {
            return <VacancyStubTempexp />;
        }
        // tempexp_35925-end

        return (
            <VacancyStub
                giftsCount={giftsCount}
                employerRegion={employerRegionTrl}
                employerRegionResumesCount={employerRegionResumesCount}
            />
        );
    };

    return (
        <>
            <VSpacing default={40} />
            {/* tempexp_37037_start*/}
            <div
                className={classnames(styles.dashboardVacancy, {
                    [styles.dashboardVacancyWithoutBg]: isFreeRiseForNewEmployerEnabled,
                })}
                ref={ref}
            >
                {/* tempexp_37037_end*/}
                <Column xs="4" s="8" m="7" l="10" container>
                    {renderVacancy()}
                    {bigHelpEnabled && <ContactPhonesTempexp />}
                </Column>
                {/* tempexp_37037_start*/}
                {isFreeRiseForNewEmployerEnabled && (
                    <>
                        <Column xs="0" s="0" m="5" l="5" container>
                            <FreeRiseBannerExp onClickMore={handleClickMore} />
                        </Column>
                        <FreeRiseMobileBannerExp
                            isFixed
                            elementName="banner_free_update_main"
                            onClickMore={handleClickMore}
                        />
                        <FreeRiseModalExp visible={visible} onClose={visibleOff} />
                    </>
                )}
                {/* tempexp_37037_end*/}
            </div>
            <PreparedVacancyTemplates />
        </>
    );
};

export default DashboardVacancy;
