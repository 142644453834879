import appInstallBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/app_install_banner_button_click';
import { urlParser } from '@hh.ru/browser-api-utils';
import { Button, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.app.promo.mobile.title',
    text: 'index.app.promo.mobile.text',
    button: 'index.app.promo.mobile.button',
};

const AppPromoMobile: TranslatedComponent = ({ trls }) => {
    const appLink = useSelector(({ appLink }) => appLink);
    const apps = useSelector((state) => state.stayInTouch.apps);
    const isAnonymousMagritteExpB = useSelector((state) => state.isAnonymousMagritteExpB);

    const getAppLinkHref = (appHref: string) => {
        const parsedUrl = urlParser(appHref);
        parsedUrl.params = { ...parsedUrl.params, main_banner: isAnonymousMagritteExpB ? 'hh_exp_b' : 'hh_exp_c' };
        return parsedUrl.href;
    };

    if (!appLink.detected) {
        return null;
    }

    return (
        <>
            <Card padding={12} paddingTop={24} borderRadius={24} showBorder stretched>
                <div className={styles.container}>
                    <StaticImg width={50} path="/images/logos/svg/hh.ru.svg?v=05022025" />
                    <VSpacing default={16} />
                    <Text typography="custom-2-semibold">{trls[TrlKeys.title]}</Text>
                    <Text typography="paragraph-3-regular" style="secondary">
                        {trls[TrlKeys.text]}
                    </Text>
                    <VSpacing default={28} />
                </div>
                <Button
                    Element="a"
                    href={getAppLinkHref(apps[0].href)}
                    size="small"
                    style="neutral"
                    mode="secondary"
                    target="_blank"
                    stretched
                    onClick={() => {
                        appInstallBannerButtonClick({ hhtmSourceLabel: 'middle' });
                    }}
                >
                    {trls[TrlKeys.button]}
                </Button>
            </Card>
            <VSpacing default={32} />
        </>
    );
};

export default translation(AppPromoMobile);
