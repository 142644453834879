// tempexp_38298_file

import { FC, useState, useEffect } from 'react';

import jobTypeScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/other/job_type_screen_shown';
import showAllJobTypesButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/other/show_all_job_types_button_click';
import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Card,
    Cell,
    CellText,
    CheckableCard,
    Checkbox,
    Link,
    Modal,
    NavigationBar,
    Text,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, InfoCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import DesktopHint from 'src/components/Tempexp38298/DesktopHint';
import GoToSearchButton from 'src/components/Tempexp38298/GoToSearchButton';
import {
    BULLET_SYMBOL,
    HINT_BULLETS,
    HINT_TYPICAL_JOBS,
    JobOption,
    JOB_OPTIONS_B,
    JOB_OPTION_TRLS,
    SelectableJobOptions,
    ScreenType,
} from 'src/components/Tempexp38298/types';
import { getHintForJobOption } from 'src/components/Tempexp38298/utils';

const TRLS = {
    modalTitle: 'Какую работу вам показать?',
    hintsTitle: 'Типы занятости',
    hintsLinkText: 'Что есть что в списке',
};

interface ModalBProps {
    isModalVisible: boolean;
    hideModal: () => void;
}

const ModalB: FC<ModalBProps> = ({ isModalVisible, hideModal }) => {
    const { isMobile } = useBreakpoint();
    const [areMobileHintsVisible, setAreMobileHintsVisible] = useState(false);
    const [selectedJobOptions, setSelectedJobOptions] = useState<SelectableJobOptions>(JOB_OPTIONS_B);

    useEffect(() => {
        if (!isModalVisible) {
            return;
        }

        jobTypeScreenShown({
            hhtmSource: ScreenType.TypeSelect,
        });
    }, [isModalVisible]);

    const showMobileHints = () => {
        showAllJobTypesButtonClick({
            hhtmSource: 'job_type_select',
        });
        setAreMobileHintsVisible(true);
    };

    const hideMobileHints = () => {
        setAreMobileHintsVisible(false);
    };

    const renderCheckableCards = () =>
        Object.entries(selectedJobOptions).map(([jobOption, isChecked]) => {
            const checkbox = <Checkbox checked={isChecked} readOnly />;
            const left = isMobile ? undefined : checkbox;
            const right = isMobile ? checkbox : <DesktopHint jobOption={jobOption as JobOption} />;

            return (
                <CheckableCard
                    key={jobOption}
                    type="checkbox"
                    checked={isChecked}
                    padding={16}
                    borderRadius={16}
                    onChange={() => {
                        setSelectedJobOptions((currentSelectedJobOptions) => ({
                            ...currentSelectedJobOptions,
                            [jobOption]: !currentSelectedJobOptions[jobOption as JobOption],
                        }));
                    }}
                >
                    <Cell left={left} right={right}>
                        <CellText>{JOB_OPTION_TRLS[jobOption as JobOption]}</CellText>
                    </Cell>
                </CheckableCard>
            );
        });

    const renderMobileHints = () =>
        Object.keys(selectedJobOptions).map((jobOption) => {
            const hint = getHintForJobOption(jobOption);
            const bullets = HINT_BULLETS[hint];
            const typicalJobs = HINT_TYPICAL_JOBS[hint];

            return (
                <Card key={jobOption} stretched padding={24} borderRadius={24} showBorder>
                    <VSpacingContainer default={16}>
                        <Text typography="subtitle-1-semibold">{JOB_OPTION_TRLS[jobOption]}</Text>
                        <VSpacingContainer default={2}>
                            {bullets.map((bullet) => (
                                <Text key={bullet} typography="label-2-regular">
                                    {`${BULLET_SYMBOL} ${bullet}`}
                                </Text>
                            ))}
                        </VSpacingContainer>
                        <Text style="secondary" typography="label-3-regular">
                            {typicalJobs}
                        </Text>
                    </VSpacingContainer>
                </Card>
            );
        });

    const renderContent = () => {
        if (isMobile && areMobileHintsVisible) {
            return (
                <VSpacingContainer default={12}>
                    {renderMobileHints()}
                    <VSpacing default={16} />
                </VSpacingContainer>
            );
        }

        const checkableCards = renderCheckableCards();
        const mobileHintsLink = isMobile && !areMobileHintsVisible && (
            <>
                <VSpacing default={16} />
                <Link iconLeft={<InfoCircleOutlinedSize16 />} onClick={showMobileHints}>
                    {TRLS.hintsLinkText}
                </Link>
                <VSpacing default={16} />
            </>
        );

        return (
            <>
                <VSpacingContainer default={12}>{checkableCards}</VSpacingContainer>
                {mobileHintsLink}
            </>
        );
    };

    const content = renderContent();
    const goToSearchButton = (
        <GoToSearchButton selectedJobOptions={selectedJobOptions} screenType={ScreenType.TypeSelect} />
    );
    const bottomSheetTitle = areMobileHintsVisible ? TRLS.hintsTitle : TRLS.modalTitle;
    const hideBottomSheet = areMobileHintsVisible ? hideMobileHints : hideModal;
    const bottomSheetFooter = areMobileHintsVisible ? null : <BottomSheetFooter>{goToSearchButton}</BottomSheetFooter>;

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={hideModal} icon={CrossOutlinedSize24} />}
                titleSize="large"
                visible={isModalVisible && !isMobile}
                onClose={hideModal}
                title={TRLS.modalTitle}
                footer={<ActionBar primaryActions={goToSearchButton} />}
            >
                {content}
            </Modal>

            <BottomSheet
                visible={isModalVisible && isMobile}
                header={
                    <NavigationBar
                        title={bottomSheetTitle}
                        right={<Action icon={CrossOutlinedSize24} onClick={hideBottomSheet} />}
                    />
                }
                footer={bottomSheetFooter}
                onClose={hideBottomSheet}
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default ModalB;
