import { useEffect, useRef, type FC } from 'react';

// tempexp_38298_start
import actionCardElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/main/action_card_element_shown';
// tempexp_38298_end
import { VSpacing } from '@hh.ru/magritte-ui';

import { useIsRussia } from 'src/hooks/useCountries';
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import CareerBanner from 'src/components/IndexPageApplicant/Actions/CareerBanner';
import CreateResume from 'src/components/IndexPageApplicant/Actions/CreateResume';
import EnableResumeAutoUpdate from 'src/components/IndexPageApplicant/Actions/EnableResumeAutoUpdate';
import KakdelaBanner from 'src/components/IndexPageApplicant/Actions/KakdelaBanner';
import ResponseStreak from 'src/components/IndexPageApplicant/Actions/ResponseStreak';
import UpdateResumes from 'src/components/IndexPageApplicant/Actions/UpdateResumes';

import styles from './actions.less';

interface ActionsProps {
    hasResumes: boolean;
    hasFinishedResumes: boolean;
}

const Actions: FC<ActionsProps> = ({ hasResumes, hasFinishedResumes }) => {
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    const isCareerExp = useExperiment('web_applicant_career_banner');

    const actionsRef = useRef<HTMLDivElement>(null);

    // tempexp_38298_start
    const shouldCallTempexp38298ExperimentHook = !isZP && isRussia;

    const isTempexp38298B = useExperiment('tempexp_38298_b', shouldCallTempexp38298ExperimentHook);
    const isTempexp38298C = useExperiment('tempexp_38298_c', shouldCallTempexp38298ExperimentHook);

    const isTempexp38298 = shouldCallTempexp38298ExperimentHook && (isTempexp38298B || isTempexp38298C);
    // tempexp_38298_end

    useEffect(() => {
        if (!actionsRef.current) {
            return;
        }

        actionCardElementShown(actionsRef.current, {
            hhtmSource: 'main',
            actionCardList: [
                !hasResumes && 'create_resume_card',
                hasFinishedResumes && 'update_resume_card',
                hasFinishedResumes && 'update_automatically_card',
                hasFinishedResumes && 'vacancies_continue_to_response_card',
                isCareerExp && 'career_skills_growth',
                // tempexp_38298_start
                isTempexp38298 && 'new_part_time_job',
                // tempexp_38298_end
            ]
                .filter(Boolean)
                .join(','),
        });
    }, [
        actionsRef,
        hasResumes,
        hasFinishedResumes,
        isCareerExp,
        // tempexp_38298_start
        isTempexp38298,
        // tempexp_38298_end
    ]);

    return (
        <div>
            {!hasResumes && <VSpacing default={28} xs={0} />}
            <div className={styles.cardsScroller}>
                <div ref={actionsRef} className={styles.cardsWrapper}>
                    {!hasResumes && <CreateResume />}
                    {hasFinishedResumes && (
                        <>
                            <UpdateResumes />
                            <EnableResumeAutoUpdate />
                        </>
                    )}
                    {!isZP && isRussia && <KakdelaBanner />}
                    {hasResumes && <ResponseStreak />}
                    {isCareerExp && <CareerBanner />}
                </div>
            </div>
        </div>
    );
};

export default Actions;
