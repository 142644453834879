// tempexp_38298_file

export enum ScreenType {
    TypeSelect = 'job_type_select',
    FormatSelect = 'job_format_select',
}

export enum EmploymentOption {
    FullTime = 'FullTime',
    PartTime = 'PartTime',
}

export const EMPLOYMENT_OPTION_TRLS: Record<EmploymentOption, string> = {
    [EmploymentOption.FullTime]: 'Постоянная',
    [EmploymentOption.PartTime]: 'Подработка',
};

export enum JobOption {
    // Для экспа B
    FullTime = 'FullTime',
    PartTime = 'PartTime',
    Project = 'Project',
    FlyInFlyOut = 'FlyInFlyOut',

    // Для экспа C
    Constant = 'Constant',
    Onsite = 'Onsite',
    Remote = 'Remote',
    Hybrid = 'Hybrid',
    Travel = 'Travel',
    FlyInFlyOut2 = 'FlyInFlyOut2',
    Temporary = 'Temporary',
    Free = 'Free',
    Freelance = 'Freelance',
    FlyInFlyOut3 = 'FlyInFlyOut3',
}

export type SelectableJobOptions = Partial<Record<JobOption, boolean>>;

export const JOB_OPTIONS_B: SelectableJobOptions = {
    [JobOption.FullTime]: false,
    [JobOption.PartTime]: false,
    [JobOption.Project]: false,
    [JobOption.FlyInFlyOut]: false,
};

export const JOB_OPTIONS_C_FULL_TIME_WITHOUT_TREK = {
    [JobOption.Onsite]: false,
    [JobOption.Remote]: false,
    [JobOption.Hybrid]: false,
    [JobOption.Travel]: false,
    [JobOption.FlyInFlyOut2]: false,
};

export const JOB_OPTIONS_C_FULL_TIME: SelectableJobOptions = {
    [JobOption.Constant]: false,
    ...JOB_OPTIONS_C_FULL_TIME_WITHOUT_TREK,
};

export const JOB_OPTIONS_C_PART_TIME_WITHOUT_TREK = {
    [JobOption.Free]: false,
    [JobOption.Freelance]: false,
    [JobOption.FlyInFlyOut3]: false,
};

export const JOB_OPTIONS_C_PART_TIME: SelectableJobOptions = {
    [JobOption.Temporary]: false,
    ...JOB_OPTIONS_C_PART_TIME_WITHOUT_TREK,
};

export const JOB_OPTION_TRLS: Record<JobOption, string> = {
    // Эксп B
    [JobOption.FullTime]: 'Постоянная работа',
    [JobOption.PartTime]: 'Подработка',
    [JobOption.Project]: 'Фриланс, проекты',
    [JobOption.FlyInFlyOut]: 'Вахта',

    // Эксп C: постоянка
    [JobOption.Constant]: '',
    [JobOption.Onsite]: 'На месте работодателя',
    [JobOption.Remote]: 'Удалённо',
    [JobOption.Hybrid]: 'Гибрид',
    [JobOption.Travel]: 'Разъездная работа',
    [JobOption.FlyInFlyOut2]: 'Вахтовый метод',

    // Эксп C: подработка
    [JobOption.Temporary]: '',
    [JobOption.Free]: 'Свободный: работа по своему графику',
    [JobOption.Freelance]: 'Фриланс: проект или разовое задание, удалённо',
    [JobOption.FlyInFlyOut3]: 'Вахта: с проживанием на месте работы',
};

export enum Hint {
    FullTime = 'FullTime',
    PartTime = 'PartTime',
    PartTimeExtended = 'PartTimeExtended',
    Project = 'Project',
    FlyInFlyOut = 'FlyInFlyOut',
}

export const HINT_BULLETS: Record<Hint, string[]> = {
    [Hint.FullTime]: [
        'Полный день',
        'По трудовому договору',
        'Выплаты 2 раза в месяц',
        'Больничные и отпуск с оплатой',
    ],
    [Hint.PartTime]: ['Свободный график', 'Можно совмещать', 'Частые выплаты'],
    [Hint.PartTimeExtended]: ['Свободный график', 'Можно совмещать', 'Частые выплаты', 'Разовые проекты'],
    [Hint.Project]: ['Разовые проекты', 'Гибкий график', 'Удалённо'],
    [Hint.FlyInFlyOut]: ['Обычно зарплата выше', 'Постоянно или подработка', 'Проживание на месте работы'],
};

export const HINT_TYPICAL_JOBS: Record<Hint, string> = {
    [Hint.FullTime]: 'Типичные профессии: офис-менеджер, строитель, полицейский',
    [Hint.PartTime]: 'Типичные профессии: водитель, курьер',
    [Hint.PartTimeExtended]: 'Типичные профессии: водитель, курьер, репетитор, сммщик',
    [Hint.Project]: 'Типичные профессии: контент-менеджер, ретушёр, сммщик',
    [Hint.FlyInFlyOut]: 'Типичные профессии: строитель, машинист',
};

export const BULLET_SYMBOL = '·';

export const MAP_JOB_OPTION_TO_ANALYTICS_TYPE: Record<JobOption, string> = {
    // Для экспа B
    [JobOption.FullTime]: 'full_time',
    [JobOption.PartTime]: 'side_job',
    [JobOption.Project]: 'freelance',
    [JobOption.FlyInFlyOut]: 'flyInFlyOut',

    // Для экспа C
    [JobOption.Constant]: 'full_time',
    [JobOption.Onsite]: 'onsite',
    [JobOption.Remote]: 'remote',
    [JobOption.Hybrid]: 'hybrid',
    [JobOption.Travel]: 'travel',
    [JobOption.FlyInFlyOut2]: 'flyInFlyOut',
    [JobOption.Temporary]: 'side_job',
    [JobOption.Free]: 'flexible',
    [JobOption.Freelance]: 'freelance',
    [JobOption.FlyInFlyOut3]: 'flyInFlyOut',
};

export interface FilterOld {
    employment?: EmploymentFormOld[];
    schedule?: ScheduleOld[];
}

enum EmploymentFormOld {
    Full = 'full',
    Part = 'part',
    Project = 'project',
}

enum ScheduleOld {
    FullDay = 'fullDay',
    Flexible = 'flexible',
    Shift = 'shift',
    Remote = 'remote',
    FlyInFlyOut = 'flyInFlyOut',
}

export interface FilterNew {
    employment_form?: EmploymentFormNew[];
    work_schedule_by_days?: ScheduleNew[];
    work_format?: WorkFormatNew[];
}

enum EmploymentFormNew {
    Full = 'FULL',
    Part = 'PART',
    Project = 'PROJECT',
    FlyInFlyOut = 'FLY_IN_FLY_OUT',
}

enum ScheduleNew {
    SevenOnZeroOff = 'SEVEN_ON_ZERO_OFF',
    SixOnOneOff = 'SIX_ON_ONE_OFF',
    FiveOnTwoOff = 'FIVE_ON_TWO_OFF',
    FourOnThreeOff = 'FOUR_ON_THREE_OFF',
    FourOnTwoOff = 'FOUR_ON_TWO_OFF',
    ThreeOnThreeOff = 'THREE_ON_THREE_OFF',
    ThreeOnTwoOff = 'THREE_ON_TWO_OFF',
    TwoOnTwoOff = 'TWO_ON_TWO_OFF',
    TwoOnOneOff = 'TWO_ON_ONE_OFF',
    OneOnThreeOff = 'ONE_ON_THREE_OFF',
    OneOnTwoOff = 'ONE_ON_TWO_OFF',
    Weekend = 'WEEKEND',
    Flexible = 'FLEXIBLE',
    Other = 'OTHER',
}

enum WorkFormatNew {
    OnSite = 'ON_SITE',
    Remote = 'REMOTE',
    Hybrid = 'HYBRID',
    FieldWork = 'FIELD_WORK',
}

export type Filter = FilterOld | FilterNew;

export const MAP_JOB_OPTION_TO_OLD_FILTERS: Record<JobOption, FilterOld> = {
    // Для экспа B
    [JobOption.FullTime]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.FullDay],
    },
    [JobOption.PartTime]: {
        employment: [EmploymentFormOld.Part],
        schedule: [ScheduleOld.Flexible, ScheduleOld.Shift],
    },
    [JobOption.Project]: {
        employment: [EmploymentFormOld.Project],
        schedule: [ScheduleOld.Remote],
    },
    [JobOption.FlyInFlyOut]: {
        schedule: [ScheduleOld.FlyInFlyOut],
    },

    // Для экспа C
    [JobOption.Constant]: {
        employment: [EmploymentFormOld.Full],
    },
    [JobOption.Onsite]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.FullDay],
    },
    [JobOption.Remote]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.Remote],
    },
    [JobOption.Hybrid]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.FullDay, ScheduleOld.Remote],
    },
    [JobOption.Travel]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.FullDay],
    },
    [JobOption.FlyInFlyOut2]: {
        employment: [EmploymentFormOld.Full],
        schedule: [ScheduleOld.FlyInFlyOut],
    },
    [JobOption.Temporary]: {
        employment: [EmploymentFormOld.Part],
    },
    [JobOption.Free]: {
        employment: [EmploymentFormOld.Part],
        schedule: [ScheduleOld.Flexible, ScheduleOld.Shift],
    },
    [JobOption.Freelance]: {
        employment: [EmploymentFormOld.Part],
        schedule: [ScheduleOld.Remote],
    },
    [JobOption.FlyInFlyOut3]: {
        employment: [EmploymentFormOld.Part],
        schedule: [ScheduleOld.FlyInFlyOut],
    },
};

export const MAP_JOB_OPTION_TO_NEW_FILTERS: Record<JobOption, FilterNew> = {
    // Для экспа B
    [JobOption.FullTime]: {
        employment_form: [EmploymentFormNew.Full],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.OnSite, WorkFormatNew.Remote, WorkFormatNew.Hybrid, WorkFormatNew.FieldWork],
    },
    [JobOption.PartTime]: {
        employment_form: [EmploymentFormNew.Part],
        work_schedule_by_days: [
            ScheduleNew.FourOnThreeOff,
            ScheduleNew.FourOnTwoOff,
            ScheduleNew.ThreeOnThreeOff,
            ScheduleNew.ThreeOnTwoOff,
            ScheduleNew.TwoOnTwoOff,
            ScheduleNew.TwoOnOneOff,
            ScheduleNew.OneOnThreeOff,
            ScheduleNew.OneOnTwoOff,
            ScheduleNew.Weekend,
            ScheduleNew.Flexible,
        ],
        work_format: [WorkFormatNew.OnSite, WorkFormatNew.Remote, WorkFormatNew.FieldWork],
    },
    [JobOption.Project]: {
        employment_form: [EmploymentFormNew.Project],
        work_schedule_by_days: [ScheduleNew.Flexible],
        work_format: [WorkFormatNew.Remote],
    },
    [JobOption.FlyInFlyOut]: {
        employment_form: [EmploymentFormNew.FlyInFlyOut],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.OnSite],
    },

    // Для экспа C
    [JobOption.Constant]: {
        employment_form: [EmploymentFormNew.Full],
    },
    [JobOption.Onsite]: {
        employment_form: [EmploymentFormNew.Full],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.OnSite],
    },
    [JobOption.Remote]: {
        employment_form: [EmploymentFormNew.Full],
        work_schedule_by_days: [ScheduleNew.Flexible],
        work_format: [WorkFormatNew.Remote],
    },
    [JobOption.Hybrid]: {
        employment_form: [EmploymentFormNew.Full],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.Hybrid],
    },
    [JobOption.Travel]: {
        employment_form: [EmploymentFormNew.Full],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.FieldWork],
    },
    [JobOption.FlyInFlyOut2]: {
        employment_form: [EmploymentFormNew.Full, EmploymentFormNew.FlyInFlyOut],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.OnSite],
    },
    [JobOption.Temporary]: {
        employment_form: [EmploymentFormNew.Part],
    },
    [JobOption.Free]: {
        employment_form: [EmploymentFormNew.Part],
        work_schedule_by_days: [ScheduleNew.Flexible],
        work_format: [WorkFormatNew.OnSite, WorkFormatNew.Remote, WorkFormatNew.FieldWork],
    },
    [JobOption.Freelance]: {
        employment_form: [EmploymentFormNew.Part, EmploymentFormNew.Project],
        work_schedule_by_days: [ScheduleNew.Flexible],
        work_format: [WorkFormatNew.Remote],
    },
    [JobOption.FlyInFlyOut3]: {
        employment_form: [EmploymentFormNew.Part, EmploymentFormNew.FlyInFlyOut],
        work_schedule_by_days: [ScheduleNew.FiveOnTwoOff, ScheduleNew.SixOnOneOff],
        work_format: [WorkFormatNew.OnSite],
    },
};
