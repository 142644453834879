// tempexp_38298_file

import { FC, useRef } from 'react';

import { Text, TooltipHover, VSpacingContainer } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { BULLET_SYMBOL, HINT_BULLETS, HINT_TYPICAL_JOBS, JobOption } from 'src/components/Tempexp38298/types';
import { getHintForJobOption } from 'src/components/Tempexp38298/utils';

interface DesktopHintProps {
    jobOption: JobOption;
}

const DesktopHint: FC<DesktopHintProps> = ({ jobOption }) => {
    const activatorRef = useRef(null);
    const hint = getHintForJobOption(jobOption);
    const bullets = HINT_BULLETS[hint];
    const typicalJobs = HINT_TYPICAL_JOBS[hint];

    return (
        <>
            <span ref={activatorRef}>
                <InfoCircleOutlinedSize24 initial="secondary" />
            </span>
            <TooltipHover placement="right-center" activatorRef={activatorRef}>
                <VSpacingContainer default={24}>
                    <VSpacingContainer default={2}>
                        {bullets.map((bullet) => (
                            <Text key={bullet} typography="paragraph-2-regular">
                                {`${BULLET_SYMBOL} ${bullet}`}
                            </Text>
                        ))}
                    </VSpacingContainer>
                    <Text typography="paragraph-2-regular">{typicalJobs}</Text>
                </VSpacingContainer>
            </TooltipHover>
        </>
    );
};

export default DesktopHint;
