import { ReactNode, MouseEvent, FC, KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, GridColumn, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import { getBreakpoint } from 'bloko/common/media';

import Compensation from 'src/components/Compensation';
import RainbowTileDrop from 'src/components/RainbowCatalog/Drop';
import VacanciesCounter from 'src/components/RainbowCatalog/VacanciesCounter';
import { useSelector } from 'src/hooks/useSelector';
import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import { VacancyCompensation } from 'src/models/vacancy/compensation.type';

import RainbowTileMobile from 'src/components/RainbowCatalog/Tile/Mobile';

import styles from './styles.less';

export const COLORS_COUNT = 6;

export interface RainbowTileProps {
    professionIndex?: number;
    promo?: boolean;
    title: string;
    vacanciesCount: number;
    tileIndex: number;
    activeTileIndex: number;
    activeDropIndex: number;
    activeTileContent: ReactNode;
    onClick: (index: number, content: ReactNode) => void;
    previousActiveTileIndex: number;
    dataQa: { tile: string };
    onClickMobile?: (title: string, body: ReactNode, vacanciesCount?: number) => void;
    mobileContent?: ReactNode;
    compensation?: VacancyCompensation;
    isSuitableVacanciesMode?: boolean;
    isXsOnly: boolean;
    isLoading?: boolean;
    hideMobile?: boolean;
    link?: string;
    pageAnalyticsValue?: string;
    children?: ReactNode;
    showSeoLinksForBots?: boolean;
    isForBottomsheet?: boolean;
}

const RainbowTile: FC<RainbowTileProps> = ({
    professionIndex,
    promo,
    title,
    compensation,
    vacanciesCount,
    tileIndex,
    activeTileIndex,
    activeDropIndex,
    activeTileContent,
    isSuitableVacanciesMode,
    previousActiveTileIndex,
    dataQa,
    onClick,
    onClickMobile,
    mobileContent,
    isXsOnly,
    link,
    isLoading,
    hideMobile,
    pageAnalyticsValue,
    children,
    showSeoLinksForBots,
    isForBottomsheet,
}) => {
    const userType = useSelector((state) => state.userType);
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const columnSize = isSuitableVacanciesMode ? '3' : '4';
    const colorIndex = professionIndex !== undefined ? professionIndex % COLORS_COUNT : undefined;
    const isCompensationVisible = compensation && (!!compensation.to || !!compensation.perModeTo);

    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const { isXS } = useBreakpoint();

    const handleClick = () => {
        Analytics.sendEvent(userType, 'profession_block', title);
        if (!link) {
            onClick(tileIndex, children);
        }
    };

    const handleClickMobile = (
        title: string,
        event: MouseEvent<HTMLAnchorElement | HTMLDivElement>,
        vacanciesCount?: number
    ) => {
        Analytics.sendEvent(userType, 'profession_block', title);
        if (link) {
            if (mainPageSignUpEnable) {
                event.preventDefault();
                dispatch(openAnonymousSignUpModal(link));
            }
        } else {
            onClickMobile?.(title, mobileContent, vacanciesCount);
        }
    };

    const handleTileClickMagritte = (e: KeyboardEvent<HTMLElement> | MouseEvent<HTMLElement>) =>
        isXS ? handleClickMobile(title, e as MouseEvent<HTMLDivElement>, vacanciesCount) : handleClick();

    const renderLink = (content: ReactNode) => {
        if (link) {
            return (
                <Link
                    to={link}
                    className="dashboard-tiles-item-link"
                    onClick={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                            dispatch(openAnonymousSignUpModal(link));
                        }
                    }}
                >
                    {content}
                </Link>
            );
        }
        return content;
    };

    const renderTitleWrapper = (content: string) => {
        if (isSuitableVacanciesMode) {
            return <BlokoLink appearance={LinkAppearance.Pseudo}>{content}</BlokoLink>;
        }
        return content;
    };

    const sendAnalytics = (title: string, index?: number) => {
        Analytics.sendHHEventButtonClick('vacancy_rainbow_catalog_item', {
            breakpoint: getBreakpoint(),
            position: index,
            value: title,
            type: isXS ? 'mobile' : `desktop_${title}`,
        });
    };

    if (isAnonymousMagritteExp) {
        return (
            <>
                <div
                    className={styles.container}
                    data-rainbow-catalog-tile="tile"
                    onClick={() => sendAnalytics(title, professionIndex)}
                >
                    <GridColumn xs={4} s={8} m={4} l={3}>
                        <div className={styles.cardContainer}>
                            {renderLink(
                                <Card
                                    showBorder
                                    verticalStretched={!isXS}
                                    stretched
                                    padding={isForBottomsheet ? 16 : 24}
                                    borderRadius={24}
                                    actionCard
                                    onClick={handleTileClickMagritte}
                                >
                                    <div className={styles.cardContent}>
                                        <div>
                                            <div className={styles.title}>
                                                <Text
                                                    typography={
                                                        isForBottomsheet ? 'label-2-regular' : 'subtitle-1-semibold'
                                                    }
                                                >
                                                    {title}
                                                </Text>
                                                {!!link && <ChevronRightOutlinedSize16 initial="secondary" />}
                                            </div>
                                            {isForBottomsheet && <VSpacing default={4} m={0} />}
                                            <Text
                                                typography={
                                                    isForBottomsheet ? 'label-3-regular' : 'paragraph-2-regular'
                                                }
                                                style={isForBottomsheet ? 'secondary' : 'primary'}
                                            >
                                                {compensation && (
                                                    <Compensation
                                                        {...compensation}
                                                        analyticsContext="RainbowTile"
                                                        dash
                                                    />
                                                )}
                                            </Text>
                                        </div>
                                        <VSpacing default={isForBottomsheet ? 4 : 16} m={0} />
                                        <Text
                                            typography="paragraph-3-regular"
                                            style={isForBottomsheet ? 'tertiary' : 'secondary'}
                                        >
                                            <VacanciesCounter value={vacanciesCount} />
                                        </Text>
                                    </div>
                                </Card>
                            )}
                        </div>
                    </GridColumn>
                </div>
                <RainbowTileDrop
                    tileIndex={tileIndex}
                    activeDropIndex={activeDropIndex}
                    activeTileContent={activeTileContent}
                    previousActiveTileIndex={previousActiveTileIndex}
                    isXsOnly={isXsOnly}
                    {...(showSeoLinksForBots ? { seoContent: children } : {})}
                />
            </>
        );
    }

    return (
        <>
            <div
                className={classnames('dashboard-tiles-item', {
                    'dashboard-tiles-item_active': tileIndex === activeTileIndex,
                    'dashboard-tiles-item_mobile-only': isXsOnly,
                    'dashboard-tiles-item_suitable-vacancies': isSuitableVacanciesMode,
                })}
                data-page-analytics-value={pageAnalyticsValue || title}
                data-rainbow-catalog-tile=""
            >
                {!isXsOnly && (
                    <Column xs="0" s="4" m={columnSize} l={columnSize}>
                        {renderLink(
                            <div className="dashboard-tiles-item-shadow" data-qa={dataQa.tile}>
                                <div
                                    className={classnames('dashboard-tiles-item-inner', {
                                        'dashboard-tiles-item-inner_loading': isLoading,
                                        'dashboard-tiles-item-inner_active': tileIndex === activeTileIndex,
                                    })}
                                    data-page-analytics-event={`vacancy_rainbow_catalog_item.desktop_${title}`}
                                    data-page-analytics-position={professionIndex}
                                    onClick={handleClick}
                                >
                                    <div className="dashboard-tiles-item__layout">
                                        <div
                                            className={classnames('dashboard-tiles-item-highlighter', {
                                                [`dashboard-tiles-item-highlighter_${String(colorIndex)}`]:
                                                    colorIndex !== undefined,
                                                'dashboard-tiles-item-highlighter_promo': promo,
                                            })}
                                        />
                                        <div className="dashboard-tiles-item__body">
                                            <div className="dashboard-tiles-item__content">
                                                <div className="dashboard-tiles-item__title">
                                                    {renderTitleWrapper(title)}
                                                </div>
                                                <div className="dashboard-tiles-item__salary">
                                                    {isCompensationVisible && (
                                                        <Compensation
                                                            {...compensation}
                                                            analyticsContext="RainbowTile"
                                                            dash
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="dashboard-tiles-item__counter">
                                                <VacanciesCounter value={vacanciesCount} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Column>
                )}
                {!hideMobile && (
                    <Column xs="4" s="0" m="0" l="0" container>
                        <RainbowTileMobile
                            colorIndex={colorIndex}
                            link={link}
                            onClick={handleClickMobile}
                            professionIndex={professionIndex || 0}
                        >
                            {title}
                        </RainbowTileMobile>
                    </Column>
                )}
            </div>
            <RainbowTileDrop
                tileIndex={tileIndex}
                activeDropIndex={activeDropIndex}
                activeTileContent={activeTileContent}
                previousActiveTileIndex={previousActiveTileIndex}
                isXsOnly={isXsOnly}
                {...(showSeoLinksForBots ? { seoContent: children } : {})}
            />
        </>
    );
};

export default RainbowTile;
