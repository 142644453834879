// tempexp_37037_file
import { FC } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { useExperimentCheckOnElementShown } from '@hh.ru/front-static-app';
import { Action, Button, Card, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Column from 'bloko/blocks/column';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import {
    useFreeRiseForNewEmployer,
    EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER,
} from 'src/components/Price/hooks/useFreeRiseForNewEmployer';
import useOnOffState from 'src/hooks/useOnOffState';

import styles from './free-rise-mobile-banner-exp.less';

interface FreeRiseMobileBannerExpProps {
    isFixed?: boolean;
    elementName: string;
    onClickMore: () => void;
}

const FreeRiseMobileBannerExp: FC<FreeRiseMobileBannerExpProps> = ({ isFixed = false, elementName, onClickMore }) => {
    const [hidden, setHidden] = useOnOffState(false);
    const { isGtS, isMobile } = useBreakpoint();
    const { shouldCheckFreeRiseForNewEmployerExp } = useFreeRiseForNewEmployer();
    const ref = useExperimentCheckOnElementShown({
        experimentNames: [EXPERIMENT_NAME_FREE_RISE_FOR_NEW_EMPLOYER],
        // Отправка события участия в эксперименте для десктопа обрабатывается отдельно
        sendCheck: shouldCheckFreeRiseForNewEmployerExp && isMobile,
    });

    if (isGtS) {
        return null;
    }

    const card = (
        <div ref={ref}>
            <ElementShownAnchor
                fn={(element: HTMLElement) =>
                    Analytics.sendHHEventElementShown(element, {
                        elementName,
                    })
                }
            >
                <Card padding={12} borderRadius={24} style="contrast" stretched>
                    <div className={styles.content}>
                        <div className={styles.text}>
                            <Text style="contrast" typography="subtitle-1-semibold">
                                Бесплатно продлите первую вакансию, если будет мало откликов
                            </Text>
                        </div>
                        <div className={styles.image}></div>
                        <VSpacing default={12} />
                        <div className={styles.buttons}>
                            <Button size="medium" style="contrast" mode="secondary" stretched onClick={onClickMore}>
                                Подробнее
                            </Button>
                            <div className={styles.actionWrapper}>
                                <Action style="contrast" icon={CrossOutlinedSize24} onClick={setHidden} />
                            </div>
                        </div>
                    </div>
                </Card>
            </ElementShownAnchor>
        </div>
    );

    return (
        <div className={classnames({ [styles.fixed]: isFixed, [styles.hidden]: hidden })}>
            {isFixed ? (
                <Column xs="4" s="8" m="0" l="0">
                    {card}
                </Column>
            ) : (
                card
            )}
        </div>
    );
};

export default FreeRiseMobileBannerExp;
