import { FC, useRef, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import {
    Cell,
    CellRightLabel,
    CellText,
    Text as TextMagritte,
    Link as LinkMagritte,
    Tag,
    useBreakpoint,
    Card,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import NumberFormatter from 'bloko/common/numberFormatter';

import VacanciesCounter from 'src/components/RainbowCatalog/VacanciesCounter';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { openAnonymousSignUpModal } from 'src/models/anonymousSignUp';
import { useMainPageSignupEnable } from 'src/models/anonymousSignUp/hooks';
import {
    ProfessionalRoleCategory,
    ProfessionalRoleCategoryCounted,
} from 'src/models/professionalRoleCategoryCollection';
import { WITH_TOP_FILTER_CATALOG } from 'src/models/topFilterCatalog';

import { sendAnalytics } from 'src/components/WorkInProfession/Utils';

export interface ListItemProps {
    category: ProfessionalRoleCategory | ProfessionalRoleCategoryCounted;
    positionIndex: number;
    isForBottomsheet?: boolean;
}

const ListItem: FC<ListItemProps> = ({ category, positionIndex, isForBottomsheet }) => {
    const mainPageSignUpEnable = useMainPageSignupEnable();
    const dispatch = useDispatch();
    const ref = useRef<HTMLAnchorElement>(null);
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isMagritte = useMagritte() || isAnonymousMagritteExp;
    const { isGtS } = useBreakpoint();

    const handleClick = (event: MouseEvent) => {
        if (mainPageSignUpEnable) {
            event.preventDefault();
            dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
        }
        sendAnalytics(category.id, positionIndex);
    };

    if (isAnonymousMagritteExp && isForBottomsheet) {
        return (
            <Card showBorder padding={16} borderRadius={16}>
                <Link
                    to={category.url}
                    additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                    isSeoLink
                    ref={ref}
                    onClick={handleClick}
                >
                    <TextMagritte typography="label-2-regular">{category.name}</TextMagritte>
                    {'count' in category && (
                        <>
                            <VSpacing default={4} m={0} />
                            <TextMagritte typography="paragraph-3-regular" style="tertiary">
                                <VacanciesCounter value={category.count} />
                            </TextMagritte>
                        </>
                    )}
                </Link>
            </Card>
        );
    }

    if (isMagritte) {
        const needToRenderTag = !isGtS && 'count' in category;
        let cellRight;
        if (needToRenderTag) {
            cellRight = isAnonymousMagritteExp ? (
                <CellRightLabel hideIcon>{NumberFormatter.format(String(category.count))}</CellRightLabel>
            ) : (
                <Tag>{NumberFormatter.format(String(category.count))}</Tag>
            );
        }

        return (
            <>
                <Cell right={cellRight}>
                    <CellText>
                        <LinkMagritte
                            style="neutral"
                            Element={Link}
                            to={category.url}
                            additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                            isSeoLink
                            ref={ref}
                            onClick={(event) => {
                                if (mainPageSignUpEnable) {
                                    event.preventDefault();
                                    dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
                                }
                                sendAnalytics(category.id, positionIndex);
                            }}
                        >
                            {category.name}
                        </LinkMagritte>
                    </CellText>
                </Cell>
            </>
        );
    }

    return (
        <li className="multiple-column-list-item">
            <Text Element="span">
                <BlokoLink
                    kind={LinkKind.Tertiary}
                    Element={Link}
                    to={category.url}
                    additionalQueryParams={{ [WITH_TOP_FILTER_CATALOG]: true }}
                    isSeoLink
                    ref={ref}
                    onClick={(event) => {
                        if (mainPageSignUpEnable) {
                            event.preventDefault();
                            dispatch(openAnonymousSignUpModal(ref.current?.href ?? ''));
                        }
                        sendAnalytics(category.id, positionIndex);
                    }}
                >
                    {category.name}
                </BlokoLink>
            </Text>
        </li>
    );
};

export default ListItem;
