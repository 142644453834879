// tempexp_38298_file

import { FC, useCallback, useMemo } from 'react';

import jobTypeSelectFormSubmit from '@hh.ru/analytics-js-events/build/xhh/applicant/other/job_type_select_form_submit';
import { useExperiment } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';

import RoutePaths from 'src/app/routePaths';

import {
    JobOption,
    SelectableJobOptions,
    ScreenType,
    type Filter,
    MAP_JOB_OPTION_TO_ANALYTICS_TYPE,
    MAP_JOB_OPTION_TO_OLD_FILTERS,
    MAP_JOB_OPTION_TO_NEW_FILTERS,
} from 'src/components/Tempexp38298/types';

const TRLS = {
    goToSearch: 'Перейти к вакансиям',
};

interface GoToSearchButtonProps {
    screenType: ScreenType;
    selectedJobOptions: SelectableJobOptions;
}

const GoToSearchButton: FC<GoToSearchButtonProps> = ({ selectedJobOptions, screenType }) => {
    const push = usePush();
    const isNewFilters = useExperiment('new_filters_vacancy_search_web', true, false);

    const goToSearch = useCallback(() => {
        const checkedJobOptions: JobOption[] = [];

        for (const [jobOption, isChecked] of Object.entries(selectedJobOptions)) {
            if (isChecked) {
                checkedJobOptions.push(jobOption as JobOption);
            }
        }

        jobTypeSelectFormSubmit({
            hhtmSource: screenType,
            types: [
                ...new Set(checkedJobOptions.map((option) => MAP_JOB_OPTION_TO_ANALYTICS_TYPE[option]).filter(Boolean)),
            ].join(','),
        });

        const filter: [string, string][] = checkedJobOptions
            .map((option) =>
                isNewFilters ? MAP_JOB_OPTION_TO_NEW_FILTERS[option] : MAP_JOB_OPTION_TO_OLD_FILTERS[option]
            )
            .reduce(
                (result: [string, string][], filter: Filter): [string, string][] => {
                    const pairs: [string, string][] = [];

                    for (const [key, values] of Object.entries(filter) as [keyof Filter, string[]][]) {
                        pairs.push(...((values || []).map((value) => [key, value]) as [string, string][]));
                    }

                    return [
                        ...result,
                        ...pairs.filter(
                            (pair) =>
                                result.findIndex((existing) => existing[0] === pair[0] && existing[1] === pair[1]) < 0
                        ),
                    ];
                },
                [] as [string, string][]
            );

        push([RoutePaths.vacancySearch, new URLSearchParams(filter).toString()].join('?'));
    }, [push, screenType, selectedJobOptions, isNewFilters]);

    const hasSelectedJobOptions = useMemo(() => Object.values(selectedJobOptions).some(Boolean), [selectedJobOptions]);

    return (
        <Button mode="primary" style="accent" onClick={goToSearch} disabled={!hasSelectedJobOptions}>
            {TRLS.goToSearch}
        </Button>
    );
};

export default GoToSearchButton;
