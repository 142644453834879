// tempexp_38298_file

import { Hint, JobOption } from 'src/components/Tempexp38298/types';

export const getHintForJobOption = (jobOption: JobOption): Hint => {
    switch (jobOption) {
        case JobOption.FullTime:
            return Hint.FullTime;
        case JobOption.PartTime:
            return Hint.PartTime;
        case JobOption.Project:
            return Hint.Project;
        case JobOption.FlyInFlyOut:
            return Hint.FlyInFlyOut;
        default:
            throw new Error(`Unknown jobOption: ${jobOption}`);
    }
};
