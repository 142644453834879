// tempexp_37037_file
import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Button, Card, Text, VSpacing } from '@hh.ru/magritte-ui';

import ElementShownAnchor from 'src/components/ElementShownAnchor';

import styles from './free-rise-banner.less';

interface FreeRiseBannerExpProps {
    onClickMore: () => void;
}

const FreeRiseBannerExp: FC<FreeRiseBannerExpProps> = ({ onClickMore }) => (
    <ElementShownAnchor
        fn={(element: HTMLElement) =>
            Analytics.sendHHEventElementShown(element, {
                elementName: 'banner_free_update_main',
            })
        }
    >
        <Card padding={12} borderRadius={24} showBorder>
            <div className={styles.bannerBody}>
                <div className={styles.bannerText}>
                    <Text typography="title-5-semibold">
                        <Text style="positive" typography="title-5-semibold" Element="span">
                            Бесплатно продлите
                        </Text>
                        &nbsp;первую вакансию, если будет мало откликов
                    </Text>
                </div>
                <VSpacing default={24} />
                <Button mode="secondary" size="medium" style="neutral" stretched onClick={onClickMore}>
                    Подробнее
                </Button>
            </div>
        </Card>
    </ElementShownAnchor>
);

export default FreeRiseBannerExp;
