import pfpLandingBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/landing/pfp_landing_banner_button_click';
import elementShown from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/landing/pfp_landing_banner_element_shown';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Button, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { MagnifierHeartOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';

import paths from 'src/app/routePaths';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'employer.index.additionalServices.pfpLandingBanner.title',
    text: 'employer.index.additionalServices.pfpLandingBanner.text',
    action: 'employer.index.additionalServices.pfpLandingBanner.action',
};

const PfpLandingBanner: TranslatedComponent = ({ trls }) => {
    return (
        <Card verticalStretched stretched showBorder borderRadius={24} padding={12}>
            <ElementShownAnchor className={styles.content} fn={elementShown} promoVariant="omsk">
                <div className={styles.banner}>
                    <div>
                        <div className={styles.bannerTitle}>
                            <MagnifierHeartOutlinedSize24 initial="primary" />
                            <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                        </div>
                        <VSpacing default={16} />
                        <Text typography="label-3-regular">{trls[TrlKeys.text]}</Text>
                        <VSpacing default={16} />
                    </div>
                    <div className={styles.bannerArt} />
                </div>
                <Button
                    Element={SpaLink}
                    to={paths.promotionPayForContact}
                    onClick={() => {
                        pfpLandingBannerButtonClick({ promoVariant: 'omsk' });
                    }}
                    size="small"
                    stretched
                    mode="secondary"
                    data-qa="pfp-landing-banner-action"
                    style="neutral"
                >
                    {trls[TrlKeys.action]}
                </Button>
            </ElementShownAnchor>
        </Card>
    );
};

export default translation(PfpLandingBanner);
