// tempexp_38298_file

import { FC, useState } from 'react';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/main/button_click';

import ActionCard, { CardType } from 'src/components/IndexPageApplicant/Actions/Card';

import ModalB from 'src/components/Tempexp38298/ModalB';
import ModalC from 'src/components/Tempexp38298/ModalC';

interface Tempexp38298Props {
    isExpC: boolean;
}

const TRLS = {
    bannerTitle: 'Работа или подработка?',
    bannerLinkText: 'Выбрать',
};

const Tempexp38298: FC<Tempexp38298Props> = ({ isExpC }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    const handleClick = () => {
        buttonClick({ buttonName: 'new_part_time_job' });
        showModal();
    };

    const modal = isExpC ? (
        <ModalC isModalVisible={isModalVisible} hideModal={hideModal} />
    ) : (
        <ModalB isModalVisible={isModalVisible} hideModal={hideModal} />
    );

    return (
        <>
            <ActionCard
                dataQa="applicant-index-nba-action_tempexp_38298"
                caption={TRLS.bannerTitle}
                linkText={TRLS.bannerLinkText}
                type={CardType.Tempexp38298}
                onClick={handleClick}
            />
            {modal}
        </>
    );
};

export default Tempexp38298;
